.root {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
  justify-self: center;
  white-space: nowrap;

  @media (max-width: 1224px) {
    justify-self: start;
  }
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  composes: marketplaceTitle from global;


  &:first-child {
    margin-top: 0;
  }

  /* we style global elements only because they are generated by markdown processor */
  & a {

    &:hover {
      color: inherit;
      text-decoration: none;
      text-decoration: underline;
    }
  }

  & ol {
    margin-top: 0px;
    padding-left: 0px;
  }

  & li {
    font-size: 16px;
    padding: 0px;
    list-style-position: inside;
  }

  & p {
    padding: 0px;
  }
}