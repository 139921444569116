.section-custom-column-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-custom-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 32px 350px;

}


@media (max-width: 1268px) {
    .section-custom-column {
        padding: 32px 50px;
    }
}

.section-custom-column h1 {

    color: black;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    margin: 0 0 10px 0;
}

@media (max-width: 1268px) {
    .section-custom-column h1 {
        font-size: 24px;
    }
}


.section-custom-column p {
    color: #1C1B1B;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin: 0 0 20px 0;

}

.section-custom-column-button {
    color: white;
    font-size: 14px;
    font-weight: 400;
    background-color: #DD1D1D;
    border: none;
    cursor: pointer;
    border-radius: 0;
    margin: 0 10px 0 0;
    padding: 10px 20px;
    width: 110px;
}